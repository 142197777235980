*{
    font-family: 'Mulish', sans-serif;
}

/* For Client Rating */
.checked {
    color: rgb(255, 227, 49);
  }

  .md\:h-20{
    margin-top:1rem;
  }

/* for seller-form */
/* form */
.owner_form{
  padding: 20px 0;
}
.form {
  position: relative;
  width: 350px;
  /* height: 500px; */
  border-radius: 20px;
  padding: 40px;
  box-sizing: border-box;
  background: #ecf0f3;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

.owner_form .brand-logo {
  height: 100px;
  width: 100px;
  background: url("/public/assets/Owner-panel.png");
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
}

.owner_form .brand-title {
  margin-top: 10px;
  font-weight: 900;
  font-size: 1.8rem;
  color: #4D4DFF;
  letter-spacing: 1px;
  text-align: center;
}

.owner_form .inputs {
  text-align: left;
  margin-top: 30px;
}

.owner_form label, .owner_form input, .owner_form button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.owner_form label {
  margin-bottom: 4px;
}

.owner_form label:nth-of-type(2) {
  margin-top: 12px;
}

.owner_form input::placeholder {
  color: gray;
}

.owner_form input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.owner_form button {
  color: white;
  margin-top: 20px;
  background: #4D4DFF;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 900;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
  transition: 0.5s;
}

.owner_form button:hover {
  box-shadow: none;
}

/* advertisement  */
article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/* basic article elements styling */
article h2 {
  margin: 0 0 18px 0;
  font-family: 'mulish';
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
  transition: color 0.3s ease-out;
}


figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  transform-origin: center;
  /* transform: scale(var(--img-scale)); */
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #4D4DFF;
}

article a:focus {
  outline: 1px dotted #4D4DFF;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

/* using the has() relational pseudo selector to update our custom properties */
article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #4D4DFF;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

/************************ 
Generic layout (demo looks)
**************************/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* body {
  margin: 0;
  padding: 48px 0;
  font-family: "Figtree", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-image: linear-gradient(45deg, #7c9885, #b5b682);
  min-height: 100vh;
} */

.articles {
  display: grid;
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

@media screen and (max-width: 960px) {
  article {
    container: card/inline-size;
  }
  .article-body p {
    display: block;
  }
}

@container card (min-width: 380px) {
  .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }
  .article-body {
    padding-left: 0;
  }
  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.advcard-sec {
  padding: 60px 10px;
}
.advcard-head{
  text-align: center;
  font-size: 35px;
  font-family:'Nunito' ;
  margin-bottom: 20px;
  padding: 60px 30px 10px;
  font-weight: 900;
}

.advhero, .About-hero{
  background: url(../public/assets/bg_yellow.png);
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px 0;
}

.advtext{
  margin-left: 2rem;
  margin-right: 1rem;
  margin-top: 20px;
  font-weight: 700;
  font-family:'Nunito' ;
}

.advimg{
  width: 50%;
  margin-right: 2rem;
  margin-top: 20px;
}
/* publisher-page */
.spacer{
  padding: 10px;
}
.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% top;
  background-image: url('../public/assets/publisher_bg.png');
  height: 80vh;
}

.align-items-center {
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
}

.justify-content-center {
  -webkit-box-pack: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.d-flex {
  display: -webkit-box!important;
  display: -ms-flexbox!important;
  display: flex!important;
}

.publisher-header{
  font-size: 45px;
  font-weight: 900;
}
.publishers, .about{
  font-size: 1.2rem;
  padding: 20px 20px;
}
.publisher-product-bg{
  background: #F9F9F9;
}
.publisher-product-heading{
  padding: 30px 0;
}
.pub-clr{
  fill: #3b82f6;
}
.about{
  padding: 60px;
}
/* services */
.Service-btn{
  text-align: center;
  background: #FFB422;
  padding: 25px 10px;
  font-family: 'Nunito';
}
.Service-btn a:hover{
  color: white;
}

@media only screen and (max-width: 600px) {
  .advimg{
    width: 80%;
    text-align: center;
    margin: 0;
  }
  .advtext{
    margin: 2rem;
  }
  .publisher-header{
    font-size: 25px;
    text-align: center;
  }
  .publishers{
    padding: 10px;
  }
  .about{
    padding: 10px;
  }
  .Service-btn a{
    font-size: 12px;
  }
}

